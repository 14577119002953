!<template>
    <div class="mianbox">
        <div class="bannerbox">
            <img src="https://api.scdamc-gz.com/wwwroot/img/banner_index.jpg">
        </div>
        <div class="shortcutbox">
            <div class="shortcutboxhead">
                快捷操作
            </div>
            <div class="shortcut_ul">
                <li class="shortcut_li" v-for="item in shortcutlist" @click="shortcutype(item)">
                    <div class="describebox">
                        <div class="describe_icon">
                            <img :src='item.icon' alt="">
                        </div>
                        <div class="describe_word">
                            {{ item.lable }}
                        </div>
                    </div>
                </li>
            </div>
        </div>
        <div class="shortcutbox_data">
            <div class="shortcutboxhead">
                数据快捷操作
            </div>
            <div class="shortcut_ul">
                <li v-for="item in shortcutdatalist" :class="item.class" @click="shortcudatatype(item)">
                    <span class="databox">
                        {{ item.data }}
                    </span>
                </li>
            </div>
        </div>
    </div>
</template>

<script>
import date from '../../../util/date';
    export default {
        data(){
            return{
                shortcutdatalist:[
                    {
                        class:'shortcut_li1',
                        data:'432290条',
                        path:'/Datas/Map/1'
                    },
                    {
                        class:'shortcut_li2',
                        data:'219496条',
                        path:'/Datas/Map/2'
                    },
                    {
                        class:'shortcut_li3',
                        data:'25170条',
                        path:'/Datas/Map/3'
                    },
                    {
                        class:'shortcut_li5',
                        data:'9城9区2县2县级市',
                        path:'/Datas/Map/4'
                    },
                    {
                        class:'shortcut_li4',
                        data:'3027条',
                        path:'/Datas/Map/5'
                    },
                    
                ],
                shortcutlist:[
                    {
                        icon:"https://api.scdamc-gz.com/wwwroot/img/icon_cx.png",
                        lable:"项目查询",
                        path:"/modules/promanage1/promanage"
                    },
                    {
                        icon:"https://api.scdamc-gz.com/wwwroot/img/icon_xz.png",
                        lable:"新增项目",
                        path:"/modules/promanage1/promanage/1"
                    },
                    {
                        icon:"https://api.scdamc-gz.com/wwwroot/img/icon_bl.png",
                        lable:"补录存量资产包",
                        path:"/modules/promanage1/promanage/2"
                    },
                    {
                        icon:"https://api.scdamc-gz.com/wwwroot/img/icon_jg.png",
                        lable:"机构管理",
                        path:"/modules/system4/mechanism"
                    },
                     {
                        icon:"https://api.scdamc-gz.com/wwwroot/img/icon_gr.png",
                        lable:"个人中心",
                        path:"/System/My"
                    },
                ]
            }
        },
        created(){
          
        },
        methods: {
            shortcutype(val){
                this.$router.replace({
                    path: val.path,
                });
            },
            shortcudatatype(val){
                this.$router.replace({
                    path: val.path,
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .mianbox{
        padding: 1.5rem;
        background-color: #f6f8f9;
        height: 100%;
        box-sizing: border-box;
        .bannerbox{
            height: 29.8rem;
            border-radius: 1rem;
            overflow: hidden;
            box-sizing: border-box;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .shortcutbox{
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.2rem;

            .shortcutboxhead{
                padding: 1.2rem 2rem;
                color: #333333;
                font-size: 1.8rem;
                border-bottom: 1px solid #EBEEF5;
            }
            .shortcut_ul{
                padding:2rem;
                display: flex;
                justify-content: space-between;
                .shortcut_li{
                    width: 28rem;
                    height: 18rem;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    border-radius: 1rem;
                    border: 1px dashed #E7E7EB;
                    background-color: #ffffff;
                    cursor: pointer;
                    .describebox{
                        width: 100%;
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        .describe_icon{
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            img{
                                width: 3.2rem;
                                height: 3.2rem;
                            }
                        }
                        .describe_word{
                            width: 100%;
                            margin-top:1rem;
                            display: flex;
                            justify-content: center;
                            font-size:1.4rem;
                            color: #333333;
                        }
                    }
                }
                .shortcut_li:hover{
                    background-color: #F6F7F8;
                }
            }
        }
        .shortcutbox_data{
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.2rem;
            .shortcutboxhead{
                padding: 1.2rem 2rem;
                color: #333333;
                font-size: 1.8rem;
                border-bottom: 1px solid #EBEEF5;
            }
            .shortcut_ul{
                padding:2rem;
                display: flex;
                justify-content: space-between;
                li{
                    list-style: none;
                    width: 28rem;
                    height: 14rem;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    position: relative;
                    .databox{
                        font-size: 1.6rem;
                        font-weight: 600;
                        color: #666666;
                        position: absolute;
                        top: 7rem;
                        left: 3rem;
                    }
                }
                .shortcut_li1{
                    background-image: url("https://api.scdamc-gz.com/wwwroot/img/databg_1.png");
                }
                .shortcut_li2{
                    background-image: url("https://api.scdamc-gz.com/wwwroot/img/databg_2.png");
                }
                .shortcut_li3{
                    background-image: url("https://api.scdamc-gz.com/wwwroot/img/databg_3.png");
                }
                .shortcut_li4{
                    background-image: url("https://api.scdamc-gz.com/wwwroot/img/databg_4.png");
                }
                .shortcut_li5{
                    background-image: url("https://api.scdamc-gz.com/wwwroot/img/databg_5.png");
                }
            }
        }
    }
</style>